<template>
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 20px 0px;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>政策法规</el-breadcrumb-item>
		</el-breadcrumb>
		<el-form ref="form" :model="form" label-width="80px" :inline="true" size="small">
			<el-form-item :label="emptyFill('标题',2)">
				<el-input style="width:586px;margin-right: 3px;" placeholder="请输入标题" v-model="form.title" clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="颁布日期">
				<el-date-picker v-model="form.issue_dates" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" clearable>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="颁布单位">
				<el-select v-model="form.issue_company" placeholder="请选择" clearable style="width: 180px;">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item,index) in issue_companys" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="条法类别">
				<el-select v-model="form.law_type" placeholder="请选择">
					<el-option-group v-for="group in law_type_options" :key="group.key" :label="group.label">
						<el-option v-for="item in group.options" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-option-group>
				</el-select>
			</el-form-item>
			<el-form-item label="法规层次">
				<el-select v-model="form.law_level" placeholder="请选择" clearable style="width: 180px;">
					<el-option label="全部" value=""></el-option>
					<el-option label="财政部规章" value="财政部规章"></el-option>
					<el-option label="财政部规范性文件" value="财政部规范性文件"></el-option>
					<el-option label="国务院文件" value="国务院文件"></el-option>
					<el-option label="其他部委文件" value="其他部委文件"></el-option>
					<el-option label="采购中心文件" value="采购中心文件"></el-option>
					<el-option label="国家法规" value="国家法规"></el-option>
					<el-option label="地方规章办法" value="地方规章办法"></el-option>
					<el-option label="国际法规" value="国际法规"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="时 效 性">
				<el-select v-model="form.law_valid" placeholder="请选择" clearable style="width: 150px;">
					<el-option label="全部" value=""></el-option>
					<el-option label="有效" value="有效"></el-option>
					<el-option label="失效" value="失效"></el-option>
					<el-option label="废止" value="废止"></el-option>
				</el-select>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-right: 0;background-color:#0084C4;"
				@click="goSearch()">搜索
			</el-button>
		</el-form>

		<div style="display:inline-block;width:880px;" v-show="list.H.length > 0">

			<div class="tableDiv" style="margin-right: 10px;">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">国务院文件</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('国务院文件')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.A" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">财政部规章</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('财政部规章')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.B" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv" style="margin-right: 10px;">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">财政部规范性文件</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('财政部规范性文件')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.C" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">其他部委文件</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('其他部委文件')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.D" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv" style="margin-right: 10px;">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">政策解读</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('政策解读')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.E" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">地方规章办法</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('地方规章办法')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.F" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. [{{item.province}}] {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv" style="margin-right: 10px;">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">相关法规</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('相关法规')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.G" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

			<div class="tableDiv">
				<table class="table1">
					<tr>
						<th style="text-align: left;font-size: 16px;">国际法规</th>
						<th></th>
						<th style="text-align:right;cursor: pointer;" @click="moreField('国际法规')">更多<i
								class="el-icon-arrow-right"></i></th>
					</tr>
					<tr v-for="(item,index) in list.H" :key="index">
						<td colspan="3">
							<p class="hrefLink" @click="goTo(item)">{{index+1}}. {{item.title}}</p>
						</td>
					</tr>
				</table>
			</div>

		</div>
		<div style="display: inline-block;width:240px;vertical-align: top;" v-show="list.H.length > 0">
			<div
				style="vertical-align: top; margin:5px 0 10px 10px;border: #0084C4 1px solid;padding:0 5px;font-size: 14px;">
				<p style="font-size: 15px;font-weight: 550;padding:8px 0 0 0;margin: 0;color:#0084C4;">分类总览</p>
				<p class="clickP" @click="moreField('国务院文件','')">1. 国务院文件({{list.aCount}})</p>
				<p class="clickP" @click="moreField('财政部规章','')">2. 财政部规章({{list.bCount}})</p>
				<p class="clickP" @click="moreField('财政部规范性文件','')">3. 财政部规范性文件({{list.cCount}})</p>
				<p class="clickP" @click="moreField('其他部委文件','')">4. 其他部委文件({{list.dCount}})</p>
				<p class="clickP" @click="moreField('政策解读','')">5. 政策解读({{list.eCount}})</p>
				<p class="clickP" @click="moreField('地方规章办法','')">6. 地方规章办法({{list.fCount}})</p>
				<p class="clickP" @click="moreField('相关法规','')">7. 相关法规({{list.gCount}})</p>
				<p class="clickP" @click="moreField('国际法规','')">8. 国际法规({{list.hCount}})</p>
			</div>

			<div style="margin:10px 0 10px 10px;border: #0084C4 1px solid;padding:0 5px;font-size: 14px;">
				<p style="font-size: 15px;font-weight: 550;padding:8px 0 0 0;margin: 0;color:#0084C4;">条法类别</p>
				<div style="display: inline-block;width: 49%;vertical-align: top;">

					<p style="font-weight: 550;color: red;cursor: pointer;"  @click="goType(law_type_options[0]['value'])">
						{{law_type_options[0]['label']}}({{list['typeCount'][law_type_options[0]['value']]}})
					</p>
					<p class="clickP" v-for="(item) in law_type_options[0]['options']" :key="item.value" @click="goType(item['value'])">
						{{item.label}}({{list['typeCount'][item.value]}})
					</p>
					
					<p style="font-weight: 550;color: red;cursor: pointer;"  @click="goType(law_type_options[3]['value'])">
						{{law_type_options[3]['label']}}({{list['typeCount'][law_type_options[3]['value']]}})
					</p>
					<p class="clickP" v-for="(item) in law_type_options[3]['options']" :key="item.value" @click="goType(item['value'])">
						{{item.label}}({{list['typeCount'][item.value]}})
					</p>
					
					<p style="font-weight: 550;color: red;cursor: pointer;"  @click="goType(law_type_options[2]['value'])">
						{{law_type_options[2]['label']}}({{list['typeCount'][law_type_options[2]['value']]}})
					</p>
					<p class="clickP" v-for="(item) in law_type_options[2]['options']" :key="item.value" @click="goType(item['value'])">
						{{item.label}}({{list['typeCount'][item.value]}})
					</p>


				</div>
				<div style="display: inline-block;width: 49%;">
					<p style="font-weight: 550;color: red;cursor: pointer;"  @click="goType(law_type_options[1]['value'])">
						{{law_type_options[1]['label']}}({{list['typeCount'][law_type_options[1]['value']]}})
					</p>
					<p class="clickP" v-for="(item) in law_type_options[1]['options']" :key="item.value" @click="goType(item['value'])">
						{{item.label}}({{list['typeCount'][item.value]}})
					</p>
					
					<p style="font-weight: 550;color: red;cursor: pointer;"  @click="goType(law_type_options[4]['value'])">
						{{law_type_options[4]['label']}}({{list['typeCount'][law_type_options[4]['value']]}})
					</p>
					<p class="clickP" v-for="(item) in law_type_options[4]['options']" :key="item.value" @click="goType(item['value'])">
						{{item.label}}({{list['typeCount'][item.value]}})
					</p>
				</div>
				
			</div>
	
			<div
				style="vertical-align: top; margin:5px 0 10px 10px;border: #0084C4 1px solid;padding:0 5px;font-size: 14px;">
				<p style="font-size: 15px;font-weight: 550;padding:8px 0 0 0;margin: 0;color:#0084C4;">法规文件</p>
				<p class="clickP" @click="goIssue(item.id)" v-for="(item,index) in issue_companys" :key="index">{{item.id}}. {{item.name}}({{list.issueCount[item.id]}})</p>
			</div>
		
		</div>



	</div>
</template>

<script>
	export default {
		name:'searchPolicy',
		data() {
			return {
				screenWidth: 1300,
				law_type_options:this.law_type_options,
				form: {
					title: '',
					issue_dates: [],
					issue_company: '',
					law_level: '',
					law_type: '',
					law_valid: ''
				},
				list: {
					A: [],
					B: [],
					C: [],
					D: [],
					E: [],
					F: [],
					G: [],
					H: [],
					typeCount:[],
					issueCount:[],
				}
			};
		},
		mounted() {
			//var that=this;
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/政策法规.jpg'),
				href: ""
			}])
			this.search();
			this.screenWidth = document.documentElement.clientWidth;
			window.addEventListener('resize', () => {
				this.screenWidth = document.documentElement.clientWidth;
			})
		},
		created() {


		},
		methods: {
			emptyFill(label,num){
				if(num == 2){
					label = '标'+'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'+'题';
				}
				return label;
			},
			search() {
				var that = this;
				this.$axios.post(this.baseUrl + 'index/pdicies/searchPdicies', this.form).then(response => {
					that.list = response.data;
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			goTo(val) {
				this.$router.push({
					path: '/policeContent',
					query: {
						id: val.id
					}
				})
			},
			goSearch() {
				let start_date = '';
				let end_date = '';
				if (this.form.issue_dates[0]) {
					start_date = this.formateDate(this.form.issue_dates[0]);
					end_date = this.formateDate(this.form.issue_dates[1]);
				}
				this.$router.push({
					path: '/policeList',
					query: {
						title: this.form.title,
						start_date: start_date,
						end_date: end_date,
						issue_company: this.form.issue_company,
						law_level: this.form.law_level,
						law_type: this.form.law_type,
						law_valid: this.form.law_valid
					}
				})
			},
			moreField(law_level) {
				this.$router.push({
					path: '/policeLevel',
					query: {
						law_level: law_level
					}
				})
			},
			goIssue(issue_company){
				this.$router.push({
					path: '/policeIssue',
					query: {
						issue_company: issue_company
					}
				})
			},
			goType(law_type) {
				this.$router.push({
					path: '/policeType',
					query: {
						law_type: law_type
					}
				})
			},
			//转换时间格式
			formateDate(datetime) {
				function addDateZero(num) {
					return (num < 10 ? "0" + num : num);
				}
				if (datetime) {
					let d = new Date(datetime);
					let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d
							.getDate()) + ' ' + addDateZero(d.getHours()) + ':' + addDateZero(d.getMinutes()) + ':' +
						addDateZero(d.getSeconds());
					return formatdatetime;
				} else {
					return '';
				}
			},
		},
	};
</script>
<style scoped>
	.tableDiv {
		display: inline-block;
		width: 435px;
		margin: 5px 0;
		padding: 0;
	}

	.table1 {
		width: 100%;
		border-collapse: collapse;
		font-size: 14px;
		border: #0084C4 1px solid;
	}

	.table1 td {
		padding: 7px 7px;
	}

	.table1 th {
		background-color: #0084C4;
		color: #fff;
		padding: 10px;
	}

	.trhead td {
		color: #0084C4;
		font-weight: 550;
	}

	.hrefLink {
		width: 415px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		margin: 0;
		cursor: pointer;
	}

	.hrefLink:hover{
		color: red;
		text-decoration:underline;
	}
	.clickP{
		cursor: pointer;
	}
	.clickP:hover{
		color: red;
		text-decoration:underline;
	}
</style>
